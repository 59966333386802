export default [
    'SPDataSourcePrototype',
    function SPBrandData(SPDataSourcePrototype) {
        SPDataSourcePrototype.attachPrototype(this);

        this._getEndpointUri = function _getEndpointUri(endpoint) {
            // SPBrandApp has some unique cases, so just return
            // whatever is given to us.
            return endpoint;
        };

        this.orderBundleAuth = function orderBundleAuth(
            orderId,
            orderDownloadId,
            email,
            termsAcceptance,
            successCallback,
            errorCallback
        ) {
            this.makePostRequest(
                'order/' + orderId + '/downloadview/' + orderDownloadId + '/auth',
                {
                    email: email,
                    doesAcceptTerms: termsAcceptance ? 1 : 0
                },
                successCallback,
                errorCallback
            );
        };

        this.logOrderBundleDownloaded = function logOrderBundleDownloaded(
            zipBundleId,
            successCallback,
            errorCallback
        ) {
            this.makePostRequest(
                'order/orderbundledownloaded',
                {
                    zip_bundle_id: zipBundleId
                },
                successCallback,
                errorCallback
            );
        };

        this.subscribe = function subscribe(subscriptionToken, successCallback, errorCallback) {
            this.makePostRequest(
                'subscribe',
                {
                    token: subscriptionToken
                },
                successCallback,
                errorCallback
            );
        };

        this.getBrandEvents = function getBrandEvents(
            pageNum,
            categoryId,
            successCallback,
            errorCallback
        ) {
            this.makeGetRequest(
                'index/data',
                {
                    category_id: categoryId,
                    page: pageNum
                },
                successCallback,
                errorCallback
            );
        };

        this.getBrandEventsWithoutCategory = function getBrandEventsWithoutCategory(
            pageNum,
            successCallback,
            errorCallback
        ) {
            this.makeGetRequest(
                'index/data',
                {
                    not_in_category: true,
                    page: pageNum
                },
                successCallback,
                errorCallback
            );
        };
    }
];
