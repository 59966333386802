/* eslint-disable func-names */

export default [
    '$scope',
    'SPBrandData',
    function BrandUnsubscribeController($scope, SPBrandData) {
        $scope.controllerName = 'BrandUnsubscribe';
        $scope.isResubscribed = false;

        $scope.subscribe = function subscribe() {
            SPBrandData.subscribe($scope.userState.unsubscribeData, function() {
                $scope.isResubscribed = true;
            });
        };
    }
];
